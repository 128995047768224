<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>
							<span class="breadcrumbLink" @click="backPageAction()">
								<i class="el-icon-back iconBack"></i>{{$t('i18nn_ca5a01a5adf20fe7')}}</span>
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{ pageTitle }}</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
		        <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">undefined</el-button> -->
				</el-col>
			</el-row>
		</div>
		<!-- 修改弹窗 -->
		<!--本页切换，新增、修改、查看等-->
		<!-- <hyPageShow :visible="dialogFormVisible" @updateVisible="updateVisible" title="undefined" :subtitle="dialogFormVisibleMsg"> -->
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" :visible.sync="dialogFormVisible" :top="'10px'" custom-class="myFullDialog2"> -->
		<div class="detEditOpenCon" style="background: #fff;">
			<el-form ref="form" :size="'small'" :rules="formRules" :model="form" label-width="100px" style="width: 1000px;"
				inline v-loading="loading" element-loading-text="加载中...">
				<!-- <h3 class="titSty1">{{ 'undefined' }}</h3> -->
				<el-alert title="提交后请提醒客服处理." type="warning" show-icon></el-alert>
				<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="title">
					<el-input type="text" v-model="form.title" :placeholder="$t('i18nn_5a9aefbc03c778f7')" maxlength="200"
						style="width: 820px;"></el-input>
				</el-form-item>

				<el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" :required="true">

					<whNoSelect ref="whNoSelect" @changeData="changWhNo2" :width="'200'"></whNoSelect>
				</el-form-item>

				<!-- <el-form-item :label="$t('i18nn_18d7892498938435')" prop="" :required="true">

					<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus2"></cusSelFuzzy>
				</el-form-item> -->

				<el-form-item :label="$t('i18nn_4dc6f04018e0f73b')" prop="" :required="true">

					<el-select v-model="form.qaType" size="" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width:200px">
						<el-option v-for="item in selectOption.wh_qa_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('i18nn_627df7b14df32cdb')" prop="">
					<el-input type="text" v-model="form.applyName" :placeholder="$t('i18nn_5a9aefbc03c778f7')" maxlength="50"
						style="width:200px"></el-input>
				</el-form-item>

				<div>
					<el-form-item :label="$t('i18nn_9666c59aedbfa70e')" prop="">
						<el-select v-model="form.qaBizType" size="" clearable :disabled="isModelEntry"
							:placeholder="$t('i18nn_2ad108ab2c560530')" style="width:200px">
							<el-option v-for="item in selectOption.wh_qa_biz_type" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('i18nn_9168144190f66f5d')" prop="">
						<el-input type="text" v-model="form.relationNo" :disabled="isModelEntry"
							:placeholder="$t('i18nn_5a9aefbc03c778f7')" maxlength="100" style="width:200px"></el-input>
					</el-form-item>

					<!-- <el-form-item :label="'关联ID'" prop="" v-if="isModelEntry">
						<el-input type="text" v-model="form.relationId" :disabled="isModelEntry" :placeholder="$t('i18nn_5a9aefbc03c778f7')" maxlength="100" style="width:200px"></el-input>
					</el-form-item> -->
				</div>



				<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
					<el-input type="textarea" :rows="3" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.remark"
						maxlength="500" show-word-limit style="width: 820px;"></el-input>
				</el-form-item>

				<!-- <h3 class="titSty1">{{$t('i18nn_c62590f388b2ce71')}}</h3> -->

				<el-card shadow="never">
					<div slot="header">
						<h3>{{$t('i18nn_ab6ea90b9164b20a')}}</h3>
					</div>
					<div>
						<el-form-item :label="$t('i18nn_466677324278a9a1')" required>
							<el-input type="textarea" :rows="3" :placeholder="$t('i18nn_5a9aefbc03c778f7')"
								v-model="form.qaProgress.qaText" maxlength="2000" show-word-limit style="width: 800px;"></el-input>
						</el-form-item>

						<el-form-item :label="$t('i18nn_805c8f776e461e39')" prop="">
							<HyQuillEditor ref="myTextEditor" :value="form.qaProgress.qaMoreText" @editorChange="onEditorChange"
								class=""></HyQuillEditor>

						</el-form-item>

						<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="" v-loading="loadingUploadFile">
							<el-button type="primary" icon="el-icon-paperclip"
								@click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

							<ul>
								<li v-for="(item,index) in form.qaProgress.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
										:src="item.url"
										:preview-src-list="form.qaProgress.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

									<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->

									<el-button @click="delFileAction($event, item,index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</li>
							</ul>
						</el-form-item>

					</div>
				</el-card>



				<!-- <h3 class="titSty1">{{ $t('i18nn_6afbe4c6dcf87a6c') }}</h3>

				<div>
					<div style="margin-bottom: 10px;">
						<el-button type="primary" icon="el-icon-plus" size="small" @click="addLe2()">{{ 'undefined' }}</el-button>
					</div>
					<ul v-loading="loading_det" element-loading-text="加载中...">
						<li v-for="(itemB, index) in form.finRecords" :key="index">
							<el-card :body-style="{padding: '10px'}">
								<div slot="header" class="">
									<strong style="padding-right: 10px;">{{index+1}}</strong>
									<el-button type="warning" size="mini" icon="el-icon-minus" @click="delLe2(index)">{{ $t('i18nn_e33c9b93c36fd250') }}</el-button>
								</div>
								<div>
									<el-form-item :label="$t('i18nn_a4eca6ff2588d791')" prop="">
										<el-input type="text" v-model="itemB.finName" :placeholder="$t('i18nn_5a9aefbc03c778f7')" size="mini"></el-input>
									</el-form-item>
									<el-form-item :label="$t('i18nn_656e30fd838de0c2')" prop="">
										<el-select v-model="itemB.addSfType" size="mini" :placeholder="$t('i18nn_2ad108ab2c560530')">
											<el-option v-for="item in selectOption.wh_add_sf_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>								</el-select>
									</el-form-item>
									
									<el-form-item :label="$t('i18nn_b0a8a31be81514ba')" prop="">
										<el-select v-model="itemB.addPriceType" size="mini" :placeholder="$t('i18nn_2ad108ab2c560530')">
											<el-option v-for="item in selectOption.wh_add_sf_price_type"  :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>					</el-select>
									</el-form-item>
									
									<el-form-item :label="$t('i18nn_f9db93b87e08763b')" prop=""><el-input-number size="mini" v-model="itemB.quantity" controls-position="right" @change="changeFinAmt(itemB,index)"></el-input-number></el-form-item>
									<el-form-item :label="$t('i18nn_6867f16a2ac5e825')" prop=""><el-input-number size="mini" v-model="itemB.price" controls-position="right" @change="changeFinAmt(itemB,index)"></el-input-number></el-form-item>
									
									<el-form-item :label="$t('i18nn_16658a855f07d171')" prop="">
										<el-input-number size="mini" v-model="itemB.finAmt" controls-position="right"></el-input-number>
									</el-form-item>
									<el-form-item :label="$t('i18nn_43a3586339251494')" prop="">
										<el-date-picker
											
											v-model="itemB.opTime"
											size="mini"
											type="date"
											:placeholder="$t('i18nn_68efabd029c860a9')"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
										></el-date-picker>
									</el-form-item>
									
									<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
										<el-input type="textarea" v-model="itemB.remark" :placeholder="$t('i18nn_5a9aefbc03c778f7')" size="mini"></el-input>
									</el-form-item>
									<div>
										<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
											<el-button type="primary" icon="el-icon-plus" size="mini" @click="openAddFile(index)">{{$t('i18nn_228e58fddadbff82')}}</el-button>
											
											<div v-for="(item,index2) in itemB.fileList" :key="index2">
												<div  style="display:flex;">
													<el-button type="warning" size="mini" icon="el-icon-minus" @click="delFile(index,index2,item)">{{ $t('i18nn_e33c9b93c36fd250') }}</el-button>
													<el-image fit="contain" :src="item.url" :preview-src-list="[item.url]" style="width: 100px; height: 100px">
														
													</el-image>
													<div>
														
														<a :href="item.url" target="_blank" style="font-size:12px;">{{item.fileName}}</a>
														
													</div>
												</div>
												
												
											</div>		
											
										</el-form-item>
									</div>
									
								</div>
							</el-card>
						</li>
					</ul>
				
				</div> -->

				<!-- <h3 class="titSty1">{{ 'undefined' }}</h3>
					<el-form-item label="备注：">
						<el-input type="textarea" placeholder="undefined" v-model="form.remark" maxlength="" show-word-limit></el-input>
					</el-form-item> -->
			</el-form>
			<div style="margin: 10px 0;">
				<!-- <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{ $t('i18nn_d552aa9f1c14363f') }}</el-button> -->
				<el-button type="primary" icon="el-icon-tickets" style="width: 220px;"
					@click="submitForm('form', '1')">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
			</div>
		</div>

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'QARecProgress/'" :openTime="FileUploadOpenTime"
			@success="FileUploadSuccess"></dialogFileUpload>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';

	import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';

	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			// cusSelFuzzy,
			whNoSelect,
			HyQuillEditor,

			dialogFileUpload
			// BillingRecords
		},
		data() {
			return {
				// cusUserId: '',

				pageTitle: this.$t('i18nn_e8564657bbe9ca53'),

				FileUploadOpenTime: '',
				// FileUploadOpenIndex:'',
				// // cusUserName: '',
				// //打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				// selBillData: {},
				// // openRowData: {},
				// // openRowIndex: {},

				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				// dialogFormInvalidDate: false,
				// currentSel: {},
				// multipleSelection: [],

				// pickerOptions: {
				// 	// disabledDate(time) {
				// 	//   return time.getTime() >= Date.now();
				// 	// },
				// 	shortcuts: [
				// 		{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Three M',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 	]
				// },
				selectOption: {
					wh_no: [],
					wh_qa_type: [],
					wh_qa_biz_type: [],
					// wh_op_status: [],
					// wh_fin_bill_status: [],
					// wh_add_sf_type: [],
					// wh_add_sf_price_type: [],
					// // wh_op_is_bill:[],
					// plStatusList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '1',
					// 		label: this.$t('i18nn_9cee201b82dbc9cb')
					// 	},
					// 	{
					// 		value: '2',
					// 		label: this.$t('i18nn_1ff1fd8ddb3e0e8d')
					// 	}
					// ]
				},

				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				// tableData: [],
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 20,
				// 	total: 0
				// },
				//详情数据
				loading_det: false,

				isModelEntry: false,

				// leaveMessage:"",
				loadingUploadFile: false,
				form: {
					"title": null, //this.$t('i18nn_7431e76029678ec7'),
					"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
					// "cusId": null,
					"qaType": null, //问题类型
					"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
					qaBizType: null,
					relationId: null,
					relationNo: null,
					applyName: null,
					"qaProgress": {
						"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
						"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
						"attachments": []
					}
				},

				formRules: {
					title: [{
						required: true,
						message: this.$t('i18nn_5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					remark: [{
						required: true,
						message: this.$t('i18nn_5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					whNo: [{
						required: true,
						message: this.$t('i18nn_2ad108ab2c560530')
					}],
					userId: [{
						required: true,
						message: this.$t('i18nn_2ad108ab2c560530')
					}]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '', //this.$t('i18nn_daf169d1f7dceda0'),
					whNo: '', //this.$t('i18nn_5e7740c52e22eefc'),
					// wh_op_is_bill:'',
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					// daterange: [],
					// wh_op_status: '',
					// wh_fin_bill_status: '',
					// plStatus:'',
					// opNo: ''
					// plName: ''
				}
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			// console.log("mounted");
			// this.getWhNoData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_qa_type', 'wh_qa_biz_type'],
				(data) => {
					this.selectOption.wh_qa_type = data['wh_qa_type'];
					this.selectOption.wh_qa_biz_type = data['wh_qa_biz_type'];
				});
		},
		methods: {
			initData() {
				this.resetForm('form');
				//是否从模块进入
				if (this.$route.query.qaBizType && this.$route.query.relationNo) {
					this.isModelEntry = true;
					this.form.qaBizType = this.$route.query.qaBizType;
					this.form.relationNo = this.$route.query.relationNo;
					this.form.relationId = this.$route.query.relationId;
					this.form.whNo = this.$route.query.whNo;

					this.$nextTick(() => {
						this.$refs.whNoSelect.init(this.form.whNo);
					})

				}
				// if (this.$route.query.id) {
				// 	this.pageTitle = this.$t('i18nn_6267f3aedf895209');
				// 	this.getDetData(this.$route.query.id);
				// }
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				// this.getPageData();
				// this.getDicData();
			},
			backPageAction() {
				// if (cusUserId) {
				// 	this.$router.push({ name: 'WhFinanceAdditionalRec', query: { cusUserId: cusUserId } });
				// } else {
				// 	this.$router.push({ name: 'WhFinanceAdditionalRec', query: { cusUserId: '' } });
				// }
				if (window.history.length > 2) {
					this.$router.go(-1);
				} else {
					this.$router.push({
						name: 'QARecList',
						query: {}
					});
				}
			},

			//附件上传成功回调
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);

			// 	// this.form. = 
			// 	let item = this.form.finRecords[this.FileUploadOpenIndex];

			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath //this.$t('i18nn_8758fd50c87d6c9c')
			// 		}
			// 	});
			// 	if (!item.fileList) {
			// 		item.fileList = [];
			// 	}
			// 	item.fileList = item.fileList.concat(fileList);

			// 	this.$set(this.form.finRecords, this.FileUploadOpenIndex, item);
			// 	// this.form.evidenceList = data.map(item=>{
			// 	// 	return {
			// 	// 		fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 	// 		url: item.filePath//this.$t('i18nn_8758fd50c87d6c9c')
			// 	// 	}
			// 	// });

			// 	// this.submitForm('form');

			// 	// this.initData();
			// 	// event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	// this.fileRelationId = row.id;
			// 	// this.FileUploadOpenTime = new Date().getTime();
			// },

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	// this.cusUserName = data.companyName;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo2(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},

			// changCus2(data) {
			// 	console.log('changCus', data);

			// 	// this.form.cusName = data.companyName;

			// 	// this.form.cusNo = data.cusNo;
			// 	this.form.cusId = data.userId;
			// },
			//计算费用明细的金额
			// changeFinAmt(item,index){
			// 	// let item = this.form.finRecords[0];
			// 	item.finAmt = (parseFloat(item.quantity?item.quantity:0)*parseFloat(item.price?item.price:0)).toFixed(2);
			// 	this.$set(this.form.finRecords,index,item);
			// },
			//添加附件
			// openAddFile(index){
			// 	this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			//新增
			// addLe2() {
			// 	this.form.finRecords.push({
			// 		finName: '',
			// 		finAmt: '0',
			// 		opTime: this.$Utils.fomatterDate_YYYYMMdd(new Date()),
			// 		addSfType: '',
			// 		addPriceType: '',
			// 		quantity : '0',
			// 		price : '0',
			// 		fileList: [],
			// 		remark: ''
			// 	});
			// },
			//删除
			// delLe2(index) {
			// 	this.form.finRecords.splice(index, 1);
			// },
			// //删除附件
			// delFile(index,index2,item) {

			// 	if(item.id && item.userId){
			// 		this.delData(this.$urlConfig.WhFileUploadDel+'/'+item.id+'/'+item.userId, {}, data => {
			// 			console.log('delData', data);
			// 			this.$message.success('删除成功！');
			// 			// this.form.finRecords[index].fileList.splice(index2, 1);
			// 			let item = this.form.finRecords[index];
			// 			item.fileList.splice(index2, 1);
			// 			this.$set(this.form.finRecords,index,item);
			// 		});
			// 	} else {
			// 		// this.form.finRecords[index].fileList.splice(index2, 1);
			// 		let item = this.form.finRecords[index];
			// 		item.fileList.splice(index2, 1);
			// 		this.$set(this.form.finRecords,index,item);
			// 	}



			// },
			//弹窗更新
			// updateVisible(val) {
			// 	this.dialogFormVisible = val;
			// },
			// openDioalog(formParm, msg) {
			// 	console.log(formParm);
			// 	this.dialogFormVisibleMsg = msg;
			// 	this.dialogFormVisible = true;
			// 	// let form = Object.assign({}, formParm);
			// 	// console.log(form);
			// 	// 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增
			// 		// 重置
			// 		// this.resetForm('form');
			// 	} else {
			// 		//修改
			// 		// 重置
			// 		// this.resetForm('form');
			// 		let form = Object.assign({}, formParm);
			// 		this.form = Object.assign({}, form);
			// 		this.getDetListData(form.opNo);
			// 	}
			// },
			//打开编辑
			// openEdit(event, row) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
			// },

			// 富文本
			onEditorChange(html) {
				// this.form.contentStr = html;
				this.form.qaProgress.qaMoreText = html;
			},

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},

			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						type: "90"
					}
				});
				if (!this.form.qaProgress.attachments) {
					this.form.qaProgress.attachments = [];
				}
				// this.form.qaProgress.attachments = fileList;
				this.form.qaProgress.attachments = this.form.qaProgress.attachments.concat(fileList);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.qaProgress.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event, row, index) {
				event.stopPropagation();
				this.loadingUploadFile = true;
				this.$http
					.delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
					.then(({
						data
					}) => {
						this.loadingUploadFile = false;
						if (200 == data.code) {
							this.form.qaProgress.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
					});
			},

			resetForm(formName) {
				console.log(formName);

				this.form = {
					"title": null, //this.$t('i18nn_7431e76029678ec7'),
					"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
					"qaType": null, //问题类型
					// "cusId": null,
					"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
					qaBizType: null,
					relationId: null,
					relationNo: null,
					applyName: null,
					"qaProgress": {
						"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
						"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
						"attachments": []
					}
				}

				this.$nextTick(() => {
					this.$refs.whNoSelect.init();
					// this.$refs.cusSelFuzzy.init();
				});
				// this[formName] = {};

				// if ('form' === formName) {
				// 	this[formName]['finRecords'] = [{
				// 			finName: '', //this.$t('i18nn_a4eca6ff2588d791'),
				// 			finAmt: '' ,//"0",
				// 			opTime:this.$Utils.fomatterDate_YYYYMMdd(new Date()),
				// 			addSfType:'',
				// 			addPriceType:'',
				// 			// "id":"",//"数据ID"
				// 		}];
				// }
				// if (this.$refs[formName]) {
				// 	this.$refs[formName].resetFields();
				// 	// this.form.remark = ""; //  备注//非必填
				// } else {
				// 	console.log('this.$refs[formName]', this.$refs[formName]);
				// }
			},

			//提交信息
			submitForm(formName, type) {
				// if (!this.form.cusId) {
				// 	this.$alert(this.$t('i18nn_5184f7a4033b4969'), this.$t('i18nn_cc62f4bf31d661e3'), {
				// 		type: 'warning'
				// 	});
				// 	return;
				// }
				if (!this.form.whNo) {
					this.$alert(this.$t('FormMsg.Select_long_wh'), this.$t('i18nn_cc62f4bf31d661e3'), {
						type: 'warning'
					});
					return;
				}
				//验证
				this.$refs[formName].validate(valid => {
					// if (true) {
					if (valid) {
						// alert('submit!');

						//浅拷贝、对象属性的合并
						// this.form = Object.assign({},form);
						// if ('1' === type) {

						//新增
						let formData = Object.assign({}, this.form);
						// formData.userId = null;
						// formData.id = null;
						// formData.bizType = '10';
						this.postData(this.$urlConfig.WhQARecAdd, formData, data => {
							console.log('postData', data);
							// this.dialogFormVisible = false;
							// this.getPageData();
							// this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('i18nn_cc62f4bf31d661e3'), {
							//   type: 'success',
							//
							// });
							this.$message.success('保存成功！');
							// if (data && data.userId) {
							// 	this.backPageAction(data.userId);
							// } else {
							this.backPageAction();
							// }
						});
						// } else if ('2' === type) {
						// 	//注销
						// 	// let formData = Object.assign({}, this.invalidDateForm);
						// 	// formData.id = this.currentSel.id;
						// 	// this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
						// } else if ('3' === type) {
						// 	//编辑
						// 	// let formData = Object.assign({}, this.form);
						// 	// // formData.id = null;
						// 	// this.postData(this.$urlConfig.WhFinanceCreate, formData, () => {
						// 	// 	this.dialogFormVisible = false;
						// 	// 	this.getPageData();
						// 	// 	// this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('i18nn_cc62f4bf31d661e3'), {
						// 	// 	//   type: 'success',
						// 	// 	//
						// 	// 	// });
						// 	// 	this.$message.success('编辑成功！');
						// 	// });
						// }
					} else {
						console.log('error submit!!');
						this.$alert('提交有误,请检查提交数据！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
						return false;
					}
				});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback(data.data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//提交数据
			// delData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.delete(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//编辑详情的数据
			// getDetData(id) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhQARecProgressGet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			console.log('data',data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.data) {
			// 				//表格显示数据
			// 				// this.form = data.data;


			// 				// this.$nextTick(() => {
			// 				// 	this.$refs.whNoSelect.init(data.data.whNo);
			// 				// 	this.$refs.cusSelFuzzy.init(data.data.userId);
			// 				// });

			// 				// this.tableData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading = false;
			// 		});
			// },
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.errorData'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_qa_type','wh_qa_biz_type'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_qa_type = data.data['wh_qa_type'];
			// 				this.selectOption.wh_qa_biz_type = data.data['wh_qa_biz_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// .table-inline-text {
	// 	ul {
	// 		display: flex;
	// 		flex-wrap: wrap;
	// 		li {
	// 			margin-right: 20px;
	// 		}
	// 	}
	// }

	// /deep/ .el-table__body {
	// 	.el-input {
	// 		input {
	// 			padding-left: 5px;
	// 			padding-right: 5px;
	// 		}
	// 	}
	// 	// .el-input-number {
	// 	//   width: 80px;
	// 	//   .el-input__inner {
	// 	//     text-align: left;
	// 	//   }
	// 	// }
	// }
</style>